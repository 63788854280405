// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "output" ]

  rent_fields_shown(event) {
    let reg_status = event.target.selectedOptions[0].value;
    if (reg_status != "Free Market") {
      $('#controlled_rents').collapse('show');
    } else {
      $('#controlled_rents').collapse('hide');
    }
  }

  resi_fields_shown(event) {
    let use = event.target.selectedOptions[0].value;
    if (use === "Residential") {
      $('#resi_options').collapse('show');
    } else {
      $('#resi_options').collapse('hide');
    }
  }

  expense_subtype_options(event) {
    // Need to fill this out to grab the expense type, make a get request to a
    // new endpoint on some controller, and have that endpoint return a turbo stream
    // that will replace the form.select for the sub type
    let expense_type = event.target.selectedOptions[0].value;

    if (expense_type ==="Management") {
      $('#management_notice').collapse('show');
    } else {
      $('#management_notice').collapse('hide');
    }

    if (expense_type ==="Vacancy") {
      $('#vacancy_notice').collapse('show');
    } else {
      $('#vacancy_notice').collapse('hide');
    }

    if (expense_type ==="Taxes") {
      $('#taxes_notice').collapse('show');
    } else {
      $('#taxes_notice').collapse('hide');
    }

    get(`/expenses/subtype_options?expense_type=${expense_type}`,{
      responseKind: "turbo-stream"
    })
  }

  get_om_document(event) {
    let om_id = event.target.selectedOptions[0].value;
    get(`/offering_memorandums/turbo-pdf/${om_id}`,{
      responseKind: "turbo-stream"
    })
  }


}
